import { useMemo } from 'react';
import styled from '@emotion/styled';

import DebouncedLoadingIndicator from 'components/debouncedLoadingIndicator/DebouncedLoadingIndicator';
import variants from 'components/editor/constants/types/editorVariants';
import Editor from 'components/editor/Editor';
import { EditorProps, Update } from 'components/editor/types';
import LockedIndicator from 'components/lockedIndicator/LockedIndicator';
import StatusSelect from 'components/statusSelect/StatusSelect';
import InfoSummary from 'features/instance/components/infoSummary/InfoSummary';
import useGetUser from 'hooks/useGetUser';
import { ResourceDetails } from 'hooks/useResourceDetails';
import { Box } from 'layouts/box/Box';
import { useStoryToolbar } from 'screens/storyV2/store/toolbar';
import { useUsers } from 'store';
import { ColorVariants } from 'types';

import useSyncScript from './api/useSyncScript';
import useScriptBeforeUnload from './hooks/useScriptBeforeUnload';
import useScriptEditor from './hooks/useScriptEditor';
import useScriptScope from './hooks/useScriptScope';
import useScriptStates from './hooks/useScriptStates';
import useWrapperMouseEvents from './hooks/useWrapperMouseEvents';

const EditorWrapper = styled('main')`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.dina.surfaceCardDark};
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Footer = styled('footer')<{
  $showLock: boolean;
  $backgroundColor: keyof ColorVariants;
  $opacity: number;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ $showLock }) => ($showLock ? '80px' : '40px')};

  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

interface ScriptProps {
  resourceDetails: ResourceDetails;
}

const Script = ({ resourceDetails }: ScriptProps) => {
  const { data, loading, error, refetch } = useSyncScript(resourceDetails.resource.mId);

  const [users] = useUsers();
  const { getUser } = useGetUser();
  const [{ editorFontSize }] = useStoryToolbar();
  const { mState, states, updateState } = useScriptStates();

  const scope = useScriptScope();
  useScriptBeforeUnload();

  const {
    shouldResetSelection,
    readLock,
    writeLock,
    content,
    lockedUserId,
    isSaving,
    isLoading,
    contentLoading,
    scriptDuration,
    onFocusEditor,
    onCancel,
    onForceUnlock,
    onEditorUpdate,
    onSave,
  } = useScriptEditor();

  const lockedBy = useMemo(
    () => (lockedUserId ? getUser(lockedUserId) : undefined),
    [getUser, lockedUserId],
  );

  const { onClick, onDoubleClick, creating, createError } = useWrapperMouseEvents({
    scope,
    resourceDetails,
    shouldCreateScript: loading ? false : !data?.mRefId,
    onFocusEditor,
    refetch,
  });

  const editorProps: EditorProps = useMemo(
    () => ({
      variant: variants.SCRIPT,
      renderToolbar: writeLock ? undefined : () => null,
      readOnly: !writeLock,
      value: content,
      update: onEditorUpdate as unknown as Update,
      onSave,
      users,
      isAllowed: true,
      placeholder: 'Type Something...',
      height: '100%',
      shouldResetSelection,
      editorFontSize,
    }),
    [content, editorFontSize, onEditorUpdate, onSave, shouldResetSelection, users, writeLock],
  );

  if (error || createError) {
    return <div>Error</div>;
  }

  return (
    <Box
      container
      width="100%"
      height="100%"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <DebouncedLoadingIndicator isLoading={loading || creating || contentLoading} />
      <EditorWrapper onClick={onClick} onDoubleClick={onDoubleClick}>
        <Editor {...editorProps} />
      </EditorWrapper>
      <Footer
        $showLock={readLock || writeLock}
        $backgroundColor={mState.backgroundColor as keyof ColorVariants}
        $opacity={mState.opacity ?? 1}
      >
        <Box
          container
          padding="0.25rem"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <InfoSummary disabled duration={scriptDuration} title="Script" />
          <StatusSelect
            value={mState.id}
            onChange={(status: string) => updateState(status)}
            options={states}
          />
        </Box>
        <LockedIndicator
          readLock={readLock}
          writeLock={writeLock}
          onCancel={onCancel}
          lockedId={lockedUserId ?? ''}
          lockedBy={lockedBy?.mTitle ?? 'Someone'}
          isSaving={isSaving}
          onDone={onSave}
          onForceUnlock={onForceUnlock}
          disableSave={!writeLock || contentLoading || isSaving || loading || isLoading}
        />
      </Footer>
    </Box>
  );
};

export default Script;
