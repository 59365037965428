import { useContext, useEffect, useMemo, useRef } from 'react';

import UserContext from 'contexts/UserContext';
import useSyncedRef from 'hooks/useSyncedRef';
import { EditorValue, Script } from 'types';
import { AuditMessageInput } from 'types/graphqlTypes';
import { getScopeFromLockedId, getUserIdFromLockedId } from 'utils/lock/lockTokenV2';

import { useScriptStore } from '../store';
import { useScriptContent } from '../store/content';
import { useScriptLock } from '../store/lock';

import useScriptScope from './useScriptScope';

export interface BeforeUnloadProps {
  lastScript: Script | null;
  lastContent: EditorValue | null;
  isCurrentlyEditing: boolean;
  wasLoading: boolean;
  unlockFn: (args_0: {
    content: EditorValue | null;
    cancelled: boolean;
    audit: AuditMessageInput;
    forceUnlock?: boolean;
  }) => Promise<string | null | undefined>;
}

const useScriptBeforeUnload = () => {
  const { mId: currentUserId } = useContext(UserContext);
  const scope = useScriptScope();

  const {
    state: { lockedBy, isLoading, script },
  } = useScriptStore();
  const { unlockScript } = useScriptLock();

  const { getEditorValue, saveTimeoutRef } = useScriptContent();

  const { isSameScope, lockedUserId } = useMemo(
    () => ({
      isSameScope: scope === getScopeFromLockedId(lockedBy),
      lockedUserId: getUserIdFromLockedId(lockedBy),
    }),
    [lockedBy, scope],
  );

  const unlockContentRef = useSyncedRef(unlockScript);
  const currentScriptRef = useSyncedRef(script);
  const isLoadingRef = useSyncedRef(isLoading);
  const isSameScopeRef = useSyncedRef(isSameScope);
  const lockedUserIdRef = useSyncedRef(lockedUserId);

  const beforeUnloadFnRef = useRef<((props: BeforeUnloadProps) => Promise<void>) | null>(null);

  /** saves content when content view unmounts */
  beforeUnloadFnRef.current = async (props: BeforeUnloadProps) => {
    const { lastScript, lastContent, isCurrentlyEditing, unlockFn, wasLoading } = props;

    const { locked } = lastScript ?? {};
    const lockedByMe = getUserIdFromLockedId(locked ?? null) === currentUserId;

    if (locked && lockedByMe && !wasLoading && isCurrentlyEditing) {
      await unlockFn({
        content: lastContent,
        cancelled: false,
        forceUnlock: false,
        audit: {
          source: 'useScriptBeforeUnload:beforeUnloadFn',
        },
      });
      /** clear debounce */
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
        saveTimeoutRef.current = null;
      }
    }
  };

  useEffect(() => {
    const onBeforeUnload = () => {
      beforeUnloadFnRef
        .current?.({
          lastScript: currentScriptRef.current ?? null,
          lastContent: getEditorValue(),
          isCurrentlyEditing: isSameScopeRef.current && lockedUserIdRef.current === currentUserId,
          unlockFn: unlockContentRef.current,
          wasLoading: isLoadingRef.current,
        })
        ?.catch(() => {});
    };
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      onBeforeUnload();

      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [
    currentUserId,
    isLoadingRef,
    isSameScopeRef,
    lockedUserIdRef,
    unlockContentRef,
    currentScriptRef,
    getEditorValue,
  ]);
};

export default useScriptBeforeUnload;
