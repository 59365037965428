import { useMemo } from 'react';

import { Participant } from 'hooks/useSharedResource';

import { CollaborationInfoForLockBar } from './types';

export {
  default as CollaborationAwareEditor,
  type CollaborationAwareEditorProps,
} from './CollaborationAwareEditor';
export * from './types';
export { default as useCollaboration } from './useCollaboration';
export { default as ActiveResourceUsers } from './ActiveResourceUsers';
export { default as ActiveUsers } from './ActiveUsers';

export function useNonCollaborativeLockBarInfo(
  others: readonly Participant<boolean>[],
): CollaborationInfoForLockBar {
  return useMemo(
    () => ({ collaborating: false, setCollaborating: undefined, others, collabLock: undefined }),
    [others],
  );
}
