import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import isNull from 'lodash/isNull';

import UserContext from 'contexts/UserContext';
import useContentResolver from 'hooks/useContentResolver';
import { EditorValue, Script } from 'types';
import { getScopeFromLockedId, getUserIdFromLockedId } from 'utils/lock/lockTokenV2';

import { useScriptStore } from '../store';
import { useScriptContent } from '../store/content';
import { useScriptLock } from '../store/lock';
import { PERIODIC_SAVE_INTERVAL } from '../utils/constants';

import useEditorInteractions from './useEditorInteractions';
import useScriptScope from './useScriptScope';

const useScriptEditor = () => {
  const { mId: currentUserId } = useContext(UserContext);
  const scope = useScriptScope();

  const {
    state: { isLocked, lockedBy, isLoading, isSaving, script, content, canUpdate, scriptDuration },
  } = useScriptStore();
  const { lockScript, unlockScript } = useScriptLock();

  const {
    getEditorValue,
    setEditorValue,
    updateContent,
    saveContent,
    saveTimeoutRef,
    periodicSaveRef,
  } = useScriptContent();

  const [shouldResetSelection, setShouldResetSelection] = useState(false);

  const { lockedByCurrentUser, lockedInSameScope, lockedUserId } = useMemo(
    () => ({
      lockedByCurrentUser: getUserIdFromLockedId(lockedBy) === currentUserId,
      lockedInSameScope: getScopeFromLockedId(lockedBy) === scope,
      lockedUserId: getUserIdFromLockedId(lockedBy),
    }),
    [currentUserId, lockedBy, scope],
  );

  const shouldSkipFetching = useMemo(() => {
    return isLocked && lockedByCurrentUser && lockedInSameScope;
  }, [isLocked, lockedByCurrentUser, lockedInSameScope]);

  const { mContentKey, mId: scriptId } = script ?? ({} as Script);

  const {
    data: s3Data,
    loading: contentLoading,
    refetch,
  } = useContentResolver(mContentKey ?? '', !mContentKey || shouldSkipFetching, true);

  const disableResettingEditorValue = useMemo(
    () => !scriptId || contentLoading || isLoading || isSaving || shouldSkipFetching,
    [contentLoading, isLoading, isSaving, scriptId, shouldSkipFetching],
  );

  /** ref to track if we unlocked script for restoring version content */
  // const wasLockedForRestoreRef = useRef(false);
  /** ref to track if there are unsaved changes */
  const hasUnsavedChangesRef = useRef(false);
  /** ref to track if initial content load */
  const initialContentLoadRef = useRef(false);

  const { readLock, writeLock } = useMemo(
    () => ({
      readLock:
        (isLocked && lockedUserId !== currentUserId) ||
        (isLocked && lockedUserId === currentUserId && !lockedInSameScope),

      writeLock: lockedInSameScope && isLocked && lockedUserId === currentUserId,
    }),
    [currentUserId, isLocked, lockedInSameScope, lockedUserId],
  );

  const clearDebounce = useCallback(() => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
      saveTimeoutRef.current = null;
    }
  }, [saveTimeoutRef]);

  /** resets editor value and content state */
  const onResetEditorValue = useCallback(
    (newValue: EditorValue | null) => {
      if (newValue) {
        updateContent(newValue);
        setEditorValue(newValue);
      } else if (isNull(newValue)) {
        updateContent(null);
        setEditorValue(null);
      }
      setShouldResetSelection(true);
    },
    [setEditorValue, updateContent],
  );

  const editorInteractions = useEditorInteractions({
    entityId: scriptId,
    state: { isLocked, lockedBy, isLoading, isSaving },
    saveTimeoutRef,
    contentLoading,
    hasUnsavedChangesRef,
    initialContentLoadRef,
    canUpdate,
    scope,
    clearDebounce,
    refetch,
    getEditorValue,
    setEditorValue,
    saveContent,
    onLock: lockScript,
    onUnlock: unlockScript,
  });

  /** sync states */
  useEffect(() => {
    /** refetch content when mContentKey changes */
    if (
      mContentKey /** once initial content has been loaded */ &&
      initialContentLoadRef.current &&
      ((!lockedByCurrentUser && !lockedInSameScope) || !isLocked)
    ) {
      refetch().catch(() => {});
    }
  }, [isLocked, lockedByCurrentUser, lockedInSameScope, mContentKey, refetch]);

  useEffect(() => {
    if (scriptId && !initialContentLoadRef.current && s3Data !== undefined && !contentLoading) {
      initialContentLoadRef.current = true;
    }
  }, [contentLoading, s3Data, scriptId]);

  useEffect(() => {
    if (disableResettingEditorValue) return;
    if ((content !== null && s3Data === null) || s3Data === undefined) return;

    /** updates content state if there's a change in s3 content */
    onResetEditorValue(s3Data);
  }, [content, disableResettingEditorValue, onResetEditorValue, s3Data]);

  useEffect(() => {
    /** resets editor selection when lock state changes */
    setShouldResetSelection(true);
  }, [isLocked]);

  useEffect(() => {
    /** saves every 5 minutes with latest content */
    periodicSaveRef.current = setInterval(() => {
      if (hasUnsavedChangesRef.current) {
        clearDebounce();

        saveContent({ content: getEditorValue(), silent: true }).catch(() => {});

        hasUnsavedChangesRef.current = false;
      }
    }, PERIODIC_SAVE_INTERVAL);

    return () => {
      if (periodicSaveRef.current) {
        clearInterval(periodicSaveRef.current);
      }
    };
  }, [clearDebounce, getEditorValue, periodicSaveRef, saveContent]);

  useEffect(
    /** clears local debounce if script is changed or view unmounted */
    () => () => {
      clearDebounce();
      updateContent(null);
    },
    [clearDebounce, scriptId, updateContent],
  );

  return {
    shouldResetSelection,
    readLock,
    writeLock,
    content,
    lockedUserId,
    isSaving,
    isLoading,
    contentLoading,
    scriptDuration,
    onResetEditorValue,
    ...editorInteractions,
  };
};

export default useScriptEditor;
