import { useCallback, useEffect, useMemo, useState } from 'react';

import useCheckUserRight from 'hooks/useCheckUserRight';
import useSharedResource from 'hooks/useSharedResource';
import { CustomEventListener } from 'types/customChannel';
import { createFilteredCustomChannel } from 'utils/filteredCustomChannel';
import { getTimestampFromLockedId } from 'utils/lock/lockTokenV2';

import { CollaborationUsage } from './types';

const LOCK_TRANSFER_TIME = 5000;

const enableLogging = false;
export const tempLog: undefined | typeof console.log = enableLogging
  ? (...args) => console.log('TEMP:', ...args) // eslint-disable-line no-console
  : undefined;

export interface CollaborationOptions {
  /** The lock used to protect saving */
  lockedBy: string | null;
  writeLock: boolean;
  locking: boolean;
  lockAsync: (timeStamp: string) => Promise<string | null>;
  addLockReleasedOnSaveHandler: (handler: (lock: string) => void) => () => void;
}

export default function useCollaboration(
  resourceType: string,
  resourceId: string | undefined,
  {
    lockedBy,
    writeLock,
    locking,
    lockAsync,
    addLockReleasedOnSaveHandler,
  }: Readonly<CollaborationOptions>,
): CollaborationUsage {
  const sharedResourceId = resourceId ? `${resourceType}-${resourceId}` : '';
  const [collaborating, setCollaborating] = useState(false);
  const [prevSyncSessionId, setPrevSyncSessionId] = useState<string | null>(null);
  const [releasedLock, setReleasedLock] = useState('');
  const sharedResourceNsChannel = sharedResourceId
    ? `default/${sharedResourceId.replace(/[^a-zA-Z0-9-]/g, '-')}`
    : '';
  const myLock = writeLock ? lockedBy : undefined;
  const myConfirmedLock = myLock && !locking ? myLock : undefined;
  useEffect(() => tempLog?.('my lock:', myLock, !locking), [myLock, locking]);
  const sharedResource = useSharedResource(sharedResourceNsChannel, {
    initialState: collaborating,
    myLock: myConfirmedLock,
  });
  const [checkUserRight] = useCheckUserRight();
  const enableCollaboration =
    !!sharedResourceNsChannel && checkUserRight('feature', 'collaborative-editing');

  const collabLock = useMemo(() => {
    if (myConfirmedLock) {
      return myConfirmedLock;
    }
    const lockOwner = sharedResource.others.find((p) => !!p.lock);
    return lockOwner?.lock;
  }, [myConfirmedLock, sharedResource.others]);
  const hasCollaboratingEditors =
    collaborating || sharedResource.others.some((p) => !!p.lock || p.state);
  const syncSessionId = collabLock ? getTimestampFromLockedId(collabLock) : null;
  const stableSyncSessionId = syncSessionId ?? prevSyncSessionId;
  const yjsSyncChannel = useMemo(() => {
    tempLog?.('new filtered channel', stableSyncSessionId);
    return stableSyncSessionId
      ? createFilteredCustomChannel(sharedResource.customChannel, stableSyncSessionId ?? '')
      : null;
  }, [stableSyncSessionId, sharedResource.customChannel]);

  const setCollaboratingEtc = useCallback(
    (val: boolean) => {
      setCollaborating(val);
      sharedResource.updateState(val);
    },
    [setCollaborating, sharedResource.updateState],
  );
  const hasOtherActiveUsers = !!sharedResource.others.length;
  useEffect(() => tempLog?.('stable sync session id:', stableSyncSessionId), [stableSyncSessionId]);

  const shouldTakeoverLock =
    !lockedBy &&
    stableSyncSessionId &&
    !syncSessionId &&
    collaborating &&
    !locking &&
    releasedLock &&
    getTimestampFromLockedId(releasedLock) === stableSyncSessionId;
  if (stableSyncSessionId && shouldTakeoverLock) {
    tempLog?.('taking over lock');
    setReleasedLock('');
    lockAsync(stableSyncSessionId)
      .then((lock) => {
        tempLog?.('YJS: locked', lock);
      })
      .catch(() => undefined);
  }

  // This effect is responsible for keeping `prevSyncSessionId` equal to the last non-empty
  // `syncSessionId` for a period of `LOCK_TRANSFER_TIME` if there are still some active editors.
  const isCollaborating = collaborating || hasCollaboratingEditors;
  useEffect(() => {
    if (syncSessionId || !isCollaborating) {
      setPrevSyncSessionId(syncSessionId);
    } else {
      const id = setTimeout(() => setPrevSyncSessionId(null), LOCK_TRANSFER_TIME);
      return () => clearTimeout(id);
    }
  }, [syncSessionId, isCollaborating, setPrevSyncSessionId]);

  // Turn off collaborating if the resource is changed (e.g. when changing daily-note date)
  useEffect(() => {
    tempLog?.('clearing collaboration');
    setCollaborating(false);
  }, [sharedResourceNsChannel, stableSyncSessionId, setCollaborating]);

  // Turn off collaborating if the resource is changed (e.g. when changing daily-note date)
  useEffect(() => {
    if (!myConfirmedLock) return;
    tempLog?.('clearing collaboration B');
    setCollaborating(false);
  }, [!!myConfirmedLock]);

  // This effect is responsible for notifying collaborating parties that the lock owner has
  // released the lock as part of leaving the editing session (save pressed or leaving scope)
  useEffect(() => {
    function onLockReleasedOnSave(lock: string) {
      sharedResource.customChannel.broadcastEvent('lockReleasedOnSave', lock);
    }
    return addLockReleasedOnSaveHandler(onLockReleasedOnSave);
  }, [addLockReleasedOnSaveHandler, sharedResource.customChannel]);

  // This effect is responsible for listening to the 'lockReleasedOnSave' event sent from
  // the lock owner when the lock is released due to a normal save operation.
  useEffect(() => {
    const onLockReleasedByLockOwner: CustomEventListener = (sourceId, type, lock) => {
      if (typeof lock !== 'string') return;
      setReleasedLock(lock);
      setTimeout(() => setReleasedLock((prev) => (prev === lock ? '' : prev)), LOCK_TRANSFER_TIME);
    };
    return sharedResource.customChannel.addEventListener(
      'lockReleasedOnSave',
      onLockReleasedByLockOwner,
    );
  }, [sharedResource.customChannel, setReleasedLock]);
  const editorInfo = useMemo(
    () => ({
      collaborating: collaborating && enableCollaboration,
      hasOtherActiveUsers,
      sharedResourceId,
      yjsSyncChannel,
    }),
    [collaborating, hasOtherActiveUsers, sharedResourceId, locking, yjsSyncChannel],
  );

  const lockBarInfo = useMemo(
    () => ({
      others: sharedResource.others,
      collaborating: collaborating && enableCollaboration,
      setCollaborating: enableCollaboration ? setCollaboratingEtc : undefined,
      collabLock,
    }),
    [collaborating, enableCollaboration, setCollaboratingEtc, sharedResource.others, collabLock],
  );
  return {
    editorInfo,
    lockBarInfo,
  };
}
