import { useCallback, useMemo, useState } from 'react';

import { EMPTY_VALUE_FOR_SYNC } from 'components/editor';
import Editor from 'components/editor/Editor';
import { EditorProps } from 'components/editor/types';
import useSyncedRef from 'hooks/useSyncedRef';

import { CollaborativeEditor, CollaborativeEditorProps } from './CollaborativeEditor';
import { CollaborationInfoForEditor } from './types';

export interface CollaborationAwareEditorProps
  extends Omit<EditorProps, 'editorCustomization' | 'readOnly' | 'renderToolbar'> {
  variant: CollaborativeEditorProps['variant'];
  collaborationInfo: CollaborationInfoForEditor;
  readLock: boolean;
  writeLock: boolean;
  reducedHeight?: string;
}

const renderNoToolbar = () => null;

export default function CollaborationAwareEditor(props: Readonly<CollaborationAwareEditorProps>) {
  const { collaborationInfo, writeLock, readLock, onSave, value, ...rest } = props;
  const { collaborating, hasOtherActiveUsers, sharedResourceId, yjsSyncChannel } =
    collaborationInfo;
  const [, /*readLockedReadyResourceId*/ setReadLockedReadyResourceId] = useState('');
  const readOnly = !collaborating && !writeLock;
  const useReducedHeight = !readOnly || readLock || hasOtherActiveUsers;
  const height = props.reducedHeight && useReducedHeight ? props.reducedHeight : props.height;
  const renderToolbar = readOnly ? renderNoToolbar : undefined;
  const onReady = useCallback(() => {
    setReadLockedReadyResourceId(sharedResourceId);
  }, [sharedResourceId]);
  const onSaveRef = useSyncedRef(onSave);
  const stableOnSave = useCallback(async () => {
    if (onSaveRef.current) await onSaveRef.current();
  }, []);

  const editorValue = !yjsSyncChannel || writeLock ? value : EMPTY_VALUE_FOR_SYNC;
  const stableResourceValue = useMemo(() => editorValue, [sharedResourceId, !yjsSyncChannel]);
  // TODO: Return both CollaborativeEditor (invisible) and Editor (visible) when readLock
  // switch visibility when onReady is triggered
  if (yjsSyncChannel) {
    const editorProps: CollaborativeEditorProps = {
      ...rest,
      yjsSyncChannel,
      readLock: undefined,
      writeLock: !readOnly,
      readOnly,
      height,
      onReady,
      onSave: stableOnSave,
      renderToolbar,
      value: stableResourceValue,
    };
    if (!writeLock) editorProps.value = EMPTY_VALUE_FOR_SYNC;
    return <CollaborativeEditor key={sharedResourceId} {...editorProps} />;
  } else {
    const editorProps = {
      ...rest,
      writeLock,
      readLock,
      readOnly,
      height,
      value,
      renderToolbar,
      stableOnSave,
    };
    return <Editor {...editorProps} />;
  }
}
