import { MutableRefObject, useCallback, useContext } from 'react';

import { ActionTypesEnum } from 'components/editor/constants/types/actionTypes';
import UserContext from 'contexts/UserContext';
import { EditorValue } from 'types';
import { AuditMessageInput } from 'types/graphqlTypes';
import { getScopeFromLockedId, getUserIdFromLockedId } from 'utils/lock/lockTokenV2';
import useLogger from 'utils/useLogger';

import { DEBOUNCE_DELAY, defaultScriptValue } from '../utils/constants';

type UpdateInput =
  | { type: ActionTypesEnum.CHANGE; payload: EditorValue }
  | {
      type: ActionTypesEnum.COMMIT_UPDATE;
      payload: EditorValue;
      commitFor: 'asset' | 'userInitiated';
    };

interface EditorInteractionsProps {
  state: {
    isLocked: boolean;
    lockedBy: string | null;
    isLoading: boolean;
    isSaving: boolean;
  };
  contentLoading: boolean;
  canUpdate: boolean;
  hasUnsavedChangesRef: MutableRefObject<boolean>;
  initialContentLoadRef: MutableRefObject<boolean>;
  saveTimeoutRef: MutableRefObject<NodeJS.Timeout | null>;
  scope: string;
  entityId?: string;
  clearDebounce: () => void;
  getEditorValue: () => EditorValue | null;
  setEditorValue: (value: EditorValue | null) => void;
  refetch: () => Promise<EditorValue | null>;
  onLock: (userId: string) => Promise<string | undefined | null>;
  onUnlock: (input: {
    content: EditorValue | null;
    cancelled: boolean;
    audit: AuditMessageInput;
    forceUnlock?: boolean;
  }) => Promise<string | undefined | null>;
  saveContent: (input: {
    content: EditorValue | null;
    silent?: boolean;
    createVersion?: boolean;
  }) => Promise<void>;
}

const useEditorInteractions = ({
  entityId,
  contentLoading,
  scope,
  canUpdate,
  hasUnsavedChangesRef,
  initialContentLoadRef,
  saveTimeoutRef,
  state: { isLocked, lockedBy, isLoading, isSaving },
  clearDebounce,
  getEditorValue,
  setEditorValue,
  refetch,
  onLock,
  onUnlock,
  saveContent,
}: EditorInteractionsProps) => {
  const { mId: currentUserId } = useContext(UserContext);

  const { log } = useLogger('useEditorInteractions');

  /** on editor interaction */
  const onForceUnlock = useCallback(async () => {
    if (!entityId || !canUpdate) return;
    await onUnlock({
      content: null,
      cancelled: false,
      audit: {
        source: 'useEditorInteractions:onForceUnlock',
      },
      forceUnlock: true,
    });

    hasUnsavedChangesRef.current = false;
  }, [canUpdate, hasUnsavedChangesRef, entityId, onUnlock]);

  const onFocusEditor = useCallback(async () => {
    if (entityId) {
      if (
        canUpdate &&
        !isLocked &&
        !isLoading &&
        !isSaving &&
        !contentLoading &&
        initialContentLoadRef.current
      ) {
        try {
          await Promise.all([refetch(), onLock(currentUserId)]);
        } catch (err) {
          log(err);
        }
      }
    }
  }, [
    entityId,
    canUpdate,
    isLocked,
    isLoading,
    isSaving,
    contentLoading,
    initialContentLoadRef,
    refetch,
    onLock,
    currentUserId,
    log,
  ]);

  const onCancel = useCallback(async () => {
    clearDebounce();

    await onUnlock({
      content: getEditorValue() ?? defaultScriptValue,
      cancelled: true,
      audit: {
        source: 'useEditorInteractions:onCancel',
      },
    });
    await refetch();

    hasUnsavedChangesRef.current = false;
  }, [clearDebounce, getEditorValue, hasUnsavedChangesRef, refetch, onUnlock]);

  const onSave = useCallback(async () => {
    clearDebounce();

    await onUnlock({
      content: getEditorValue() ?? defaultScriptValue,
      cancelled: false,
      audit: {
        source: 'useEditorInteractions:onSave',
      },
    });

    hasUnsavedChangesRef.current = false;
  }, [clearDebounce, getEditorValue, hasUnsavedChangesRef, onUnlock]);

  const onContentUpdate = (newContent: EditorValue | null) => {
    setEditorValue(newContent);
    hasUnsavedChangesRef.current = true;

    clearDebounce();

    /** trigger debounce */
    saveTimeoutRef.current = setTimeout(() => {
      if (hasUnsavedChangesRef.current) {
        saveContent({ content: newContent, silent: true }).catch(() => {});

        if (getEditorValue() === newContent) hasUnsavedChangesRef.current = false;
      }
    }, DEBOUNCE_DELAY);
  };

  /** handles editor updates */
  const onEditorUpdate = (input: UpdateInput) => {
    const isLockedByCurrentUser =
      isLocked &&
      getUserIdFromLockedId(lockedBy) === currentUserId &&
      getScopeFromLockedId(lockedBy) === scope;

    if (!isLockedByCurrentUser) return;

    const { type, payload } = input;

    switch (type) {
      case ActionTypesEnum.CHANGE:
        onContentUpdate(payload);
        break;

      case ActionTypesEnum.COMMIT_UPDATE:
        saveContent({
          content: getEditorValue() ?? defaultScriptValue,
          silent: true,
          createVersion: true,
        }).catch(log);
        hasUnsavedChangesRef.current = false;
        break;

      default:
        return null;
    }
  };

  return {
    onEditorUpdate,
    onFocusEditor,
    onCancel,
    onSave,
    onForceUnlock,
  };
};

export default useEditorInteractions;
