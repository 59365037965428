import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';

import { isOlderSlateValue, migrateValue } from 'components/editor';
import initialValues from 'components/editor/constants/initialValues';
import { getWords } from 'components/editor/utils';
import UserContext from 'contexts/UserContext';
import useArchiveMember from 'hooks/useArchiveMember';
import useCheckPublishingPermission from 'hooks/useCheckPublishingPermission';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import useDuplicateInstance from 'hooks/useDuplicateInstance';
import useGetPlatforms from 'hooks/useGetPlatforms';
import useGetUser from 'hooks/useGetUser';
import useLockInstance from 'hooks/useLockInstance';
import useNewItems from 'hooks/useNewItems';
import usePostEvent from 'hooks/usePostEvent';
import useSettingsValue from 'hooks/useSettingsValue';
import useUpdateDurationMeta from 'hooks/useUpdateDurationMeta';
import useUpdateInstance from 'hooks/useUpdateInstance';
import useUpdateRelatedMembers from 'hooks/useUpdateRelatedMembers';
import useUpdateTwitterThreadCount from 'hooks/useUpdateTwitterThreadCount';
import memberTypes from 'operations/memberTypes';
import UPDATE_INSTANCE from 'operations/mutations/updateStoryInstance';
import GET_RUNDOWN from 'operations/queries/getRundown';
import GET_STORY from 'operations/queries/getStory';
import { getMemberQuery } from 'operations/queryVariables';
import {
  durationTypes,
  filterEditorMeta,
  getDuration,
  getSeconds,
  getTime,
} from 'screens/rundown/components/editor/utils';
import respectHostReadSpeed from 'screens/rundown/utils/respectHostReadSpeed';
import {
  Asset,
  AutomationItem,
  CustomElement,
  EditorValue,
  Instance,
  PlatformAccount,
  Story,
} from 'types';
import { MemberType, MMetaDataField, Rundown, UpdateInstanceInput } from 'types/graphqlTypes';
import { UNTITLED_STORY } from 'utils/constants';
import { getLastVersionFromContentKey, replaceTab } from 'utils/content/contentUtil';
import { isCmsInstance, isLinearInstance } from 'utils/instance/checkInstancePlatform';
import countTwitterThread from 'utils/instance/countTwitterThread';
import { useRestrictedPlatformPropsHelper } from 'utils/instance/restrictedPlatformPropsHelper';
import variants from 'utils/instance/variants';
import {
  getDinaSessionIdFromLockedId,
  getScopeFromLockedId,
  getUserIdFromLockedId,
  resetLockToken,
} from 'utils/lock/lockTokenV2';
import { merge } from 'utils/metadata';
import { uploadToS3 } from 'utils/s3Utils';
import { getDinaSessionId } from 'utils/sessionId';

import { useInstanceMolecule } from '../store/instance';
import { getVariant } from '../utils';
import { addInstancePublishDetails } from '../utils/schedulingUtils';

import useInstanceAsset from './useInstanceAsset';
import useInstanceMetadata from './useInstanceMetadata';
import useInstancePermissions from './useInstancePermissions';
import useInstancePlaceholder from './useInstancePlaceholder';

const getWordCount = (nodes: CustomElement[]) => getWords(nodes, true)?.length ?? 0;

const userInitiatedLabel = 'userInitiated';

export type EditorUpdateInput =
  | {
      type: 'asset-insert';
      payload: {
        file: File;
        data: { mTitle?: string; mRefId?: string };
        bypassUploadApi: boolean;
        uploadProgressCallback: () => void;
      };
    }
  | {
      type: 'create-asset';
      payload: {
        asset: Asset;
      };
    }
  | {
      type: 'asset-update';
      payload: {
        asset: Asset;
      };
    }
  | {
      type: 'create-placeholder';
      payload: {
        title: string;
        itemType: string;
      };
    }
  | {
      type: 'remove-placeholder';
      payload: {
        placeholder: {
          mId: string;
          mRefId: string;
        };
      };
    }
  | {
      type: 'change';
      payload: EditorValue;
    }
  | {
      type: 'commit-update';
      payload: EditorValue;
      commitFor: 'asset' | 'userInitiated';
    };

export type InstanceChangeObject =
  | {
      changeType: 'schedule-and-destination-and-expiry';
      publishingSettings: {
        publishTime: string | null;
        accountUrl: string | null;
        expiryTime: string | null;
      };
    }
  | {
      changeType: 'schedule-and-destination';
      publishingSettings: {
        publishTime: string | null;
        accountUrl: string | null;
      };
    }
  | {
      changeType: 'expiry-and-destination';
      publishingSettings: {
        expiryTime: string | null;
        accountUrl: string | null;
      };
    }
  | {
      changeType: 'schedule-and-expiry';
      schedule: { expiryTime: string | null; publishTime: string | null };
    }
  | {
      changeType: 'publishingSchedule';
      schedule: { publishTime: string | null };
    }
  | {
      changeType: 'expirySchedule';
      schedule: { expiryTime: string | null };
    }
  | {
      changeType: 'destination';
      accountUrl: string | null;
    }
  | {
      changeType: 'content';
      content: EditorValue;
    }
  | {
      changeType: 'title';
      title: string;
    }
  | {
      changeType: 'status';
      status: string;
    }
  | {
      changeType: 'description';
      description: string;
    };

export type UpdateInputParameters = {
  instance: Instance;
  metadata?: MMetaDataField[];
  unlock?: boolean;
  audit?: { source: string };
  content?: EditorValue;
  items?: AutomationItem[];
  version?: string;
  autosave?: boolean;
};

interface InstanceUpdateMutation {
  updateInstance: MemberType;
}

const useInstanceCore = () => {
  const {
    useInstanceValue,
    useIsSavingInstance,
    useHasChanges,
    usePlatform,
    usePlatformVariant,
    useIsCancelled,
    useCurrentDestination,
    useDisableEdit,
    useMetadata,
    useEditorData,
    useSkipDownload,
    useLockedBy,
    useLocking,
    useLockedByCurrentUser,
    useReadLock,
    useWriteLock,
    useOwnsLockElsewhere,
    useInstanceContentData,
    useInstanceContentLoading,
    usePublishingPointValue,
    useWordCount,
    instanceRef,
    initialContentRef,
    editorValueRef,
    writeLockRef,
    lockedIdRef,
    onSaveHotkeyVersion,
    loadingRef,
    contentRefetchRef,
    beforeUnloadRef,
    currentEditingScope,
  } = useInstanceMolecule();
  const {
    hostReadSpeed,
    canUpdateScriptDurationSettingsValue,
    blankMetaData,
    updateMetadataState,
    defaultMetadata,
    autoClipDurationSettingsValue,
    findDurationKey,
    debouncedScriptDurationUpdate,
  } = useInstanceMetadata();
  const { canShowCmsIframe, canUpdateInstance, canEditReadyInstance, canPreviewInstance } =
    useInstancePermissions();
  const { removePlaceholder, handleCreatePlaceholder } = useInstancePlaceholder();
  const { updateAsset, createAsset, onAssetInsert } = useInstanceAsset();
  const { addItem } = useNewItems();

  const user = useContext(UserContext);
  const client = useApolloClient();
  const [updateInstance] = useUpdateInstance();
  const [lockInstance] = useLockInstance();
  const [archiveMember] = useArchiveMember();
  const [postEvent] = usePostEvent();
  const { getUserTitle } = useGetUser();
  const [restrictedPlatform] = useCheckPublishingPermission();
  const [updateRelatedMembersMutation, updatingRelatedMembers] = useUpdateRelatedMembers();
  const [createDuplicateInstance] = useDuplicateInstance();
  const [getSettingsValue] = useSettingsValue();
  const [handleTwitterThreadChange] = useUpdateTwitterThreadCount(
    blankMetaData,
    updateMetadataState,
  );
  const [handleDurationFieldsChange] = useUpdateDurationMeta(blankMetaData, () => {});
  const [updateStoryInstance] = useMutation<InstanceUpdateMutation>(UPDATE_INSTANCE, {
    update: (_, mutationResult) => {
      if (mutationResult.data?.updateInstance) {
        addItem(mutationResult.data.updateInstance);
      }
    },
  });

  const instance = useInstanceValue();
  const [platform, setPlatform] = usePlatform();
  const [platformVariant, setPlatformVariant] = usePlatformVariant();
  const [currentDestination, setCurrentDestination] = useCurrentDestination();
  const [disableEdit, setDisableEdit] = useDisableEdit();
  const [metadata] = useMetadata();
  const [editorData, setEditorData] = useEditorData();
  const [lockedByUser, setLockedByUser] = useLockedBy();
  const [locking, setLocking] = useLocking();
  const [readLock, setReadLock] = useReadLock();
  const [writeLock, setWriteLock] = useWriteLock();
  const [, setOwnsLockElsewhere] = useOwnsLockElsewhere();
  const [lockedByCurrentUser, setLockedByCurrentUser] = useLockedByCurrentUser();
  const [s3Data] = useInstanceContentData();
  const [contentLoading] = useInstanceContentLoading();
  const [, setIsCancelled] = useIsCancelled();
  const [isSavingInstance, setIsSavingInstance] = useIsSavingInstance();
  const [hasChanges, setHasChanges] = useHasChanges();
  const [, setSkipDownload] = useSkipDownload();
  const [wordCount, setWordCount] = useWordCount();
  const [checkUserRight] = useCheckUserRight();
  const canSeeNewCmsWorkflow = checkUserRight('feature', 'cms-blocks');
  const publishingPoint = usePublishingPointValue();
  const { approvalState, isApprovalWorkflowEnabled, isApproved } =
    useRestrictedPlatformPropsHelper(publishingPoint);
  const hasAccessToApprovalState = checkUserRight('state', approvalState ?? '');

  const { mId: userId } = user;

  const { data: storyData, loading: storyLoading } = useQuery<{ getStory: Story }>(GET_STORY, {
    variables: getMemberQuery(instance?.mStoryId ?? ''),
    fetchPolicy: 'cache-first',
    skip:
      !!instance?.mTemplateId ||
      !instance?.mStoryId ||
      instance?.mStoryId === instance?.mProperties?.account?.accountId,
  });

  const {
    platforms,
    getPlatformVariant,
    loading: platformsLoading,
  } = useGetPlatforms(storyData?.getStory?.mPublishingAt || null);

  const editorValue = useMemo(
    () =>
      (editorData && isOlderSlateValue(editorData)
        ? migrateValue(editorData, {})
        : editorData) as EditorValue,
    [editorData],
  );

  const destination = useMemo(
    () => ({
      id: instance?.mProperties?.account?.accountId ?? '',
      title: instance?.mProperties?.account?.accountTitle ?? '',
      value:
        instance?.mProperties?.platform === 'linear' && instance?.mProperties?.account?.accountId
          ? instance?.mProperties?.account?.accountId ?? ''
          : instance?.mProperties?.account?.accountTitle ?? '',
    }),
    [
      instance?.mProperties?.account?.accountId,
      instance?.mProperties?.account?.accountTitle,
      instance?.mProperties?.platform,
    ],
  );

  const { storyPublishingAt, storyTitle } = useMemo(() => {
    const publishingAt = storyData?.getStory?.mPublishingAt ?? null;
    const title =
      storyData?.getStory.mTitle === UNTITLED_STORY
        ? ''
        : (storyData as { getStory: Story })?.getStory.mTitle;

    return { storyPublishingAt: publishingAt, storyTitle: title };
  }, [storyData]);

  const variant = getVariant(instance?.mProperties?.platform ?? '');

  const clipDuration = getDuration(metadata, durationTypes.CLIP_DURATION);
  const scriptDuration = getDuration(metadata, durationTypes.SPEAK_DURATION);
  const totalDuration = getDuration(metadata, durationTypes.TOTAL_DURATION);

  const twitterThreadCount = countTwitterThread(
    instance?.mMetaData,
    editorValueRef.current,
    blankMetaData,
  );

  const placeholderFormat = {
    defaultFormat: getSettingsValue(
      `mam.placeholderName.${instance?.mProperties?.platform}`,
      'mam.placeholderName',
    ) as string,
    defaultHint: getSettingsValue('mam.placeholderName.defaultHint') as string,
    maxLength: getSettingsValue('mam.placeholderName.maxLength') as number,
    maxLengthMessage: getSettingsValue('mam.placeholderName.maxLengthMessage') as string,
    characters: getSettingsValue('mam.placeholderName.characters') as string,
    charactersMessage: getSettingsValue('mam.placeholderName.charactersMessage') as string,
    conjunctiveCharacter: getSettingsValue('mam.placeholderName.conjunctiveCharacter') as string,
    hasDuplicateMessage: getSettingsValue('mam.placeholderName.hasDuplicateMessage') as string,
  };

  const placeHolderFormatValues = useMemo(
    () => ({
      ...instance,
      rundown: { mTitle: currentDestination?.title },
      story: {
        mTitle: storyTitle,
        mPublishingAt: storyPublishingAt,
      },
    }),
    [currentDestination?.title, instance, storyPublishingAt, storyTitle],
  );

  const getPlatformInitialValue = useCallback(() => {
    const platformStructure =
      platformVariant?.platformStructure ?? platform?.mProperties?.platformStructure;
    const isCmsBlock = variant === variants.CMS && platformStructure?.variant === 'blocks';
    const isAllowed = variant !== variants.CMS || canShowCmsIframe;
    return initialValues(variant, isAllowed, isCmsBlock, canSeeNewCmsWorkflow);
  }, [
    canShowCmsIframe,
    platform?.mProperties?.platformStructure,
    platformVariant?.platformStructure,
    variant,
    canSeeNewCmsWorkflow,
  ]);

  const handleInstanceUpdate = useCallback(
    async ({
      instance: inputInstance,
      metadata: inputMetadata,
      content,
      items,
      version,
      unlock = false,
      autosave = !unlock,
      audit,
    }: UpdateInputParameters) => {
      if (!inputInstance) return;

      const response = (await updateInstance({
        metadata: respectHostReadSpeed(
          inputMetadata,
          hostReadSpeed,
          canUpdateScriptDurationSettingsValue,
        ) as MMetaDataField[],
        instanceId: inputInstance.mId,
        items,
        unlock,
        autosave,
        content,
        version,
        audit,
        onMetadataUpdated: () => {},
      })) as { data: { updateInstance: Instance } } | null;

      const isCancelledEvent = audit?.source?.includes('cancelled');

      /** update editor version information from when user press ctrl+s to make sure
       * later saves is sent with correct version.
       */
      if (!unlock && version === userInitiatedLabel && response?.data?.updateInstance) {
        const result = response.data.updateInstance;
        const contentVersion = getLastVersionFromContentKey(result.mContentKey);
        if (editorValueRef.current?.properties?.version !== contentVersion)
          editorValueRef.current = {
            ...(editorValueRef.current as EditorValue),
            properties: {
              ...content?.properties,
              version: contentVersion,
              story: result.mStoryId,
              mUpdatedById: result.mUpdatedById,
            },
          };
      }

      if (isCancelledEvent || !canPreviewInstance) return;

      if (unlock && isCmsInstance(inputInstance)) {
        await postEvent(inputInstance?.mId, 'publish', 'preview', inputInstance.mType);
      }
    },
    [
      canPreviewInstance,
      canUpdateScriptDurationSettingsValue,
      hostReadSpeed,
      postEvent,
      updateInstance,
      editorValueRef,
    ],
  );

  const saveInstanceWithContent = useCallback(
    async (params: UpdateInputParameters) => {
      const { content, instance: inputInstance } = params;
      const isLinear = isLinearInstance(inputInstance);
      const updatedMetadata = merge(
        isLinear
          ? (
              handleDurationFieldsChange as (
                content: EditorValue | undefined,
                hostReadRate: number,
                metadata: MMetaDataField[],
                lockedByUser: boolean,
              ) => void
            )?.(
              content,
              hostReadSpeed,
              inputInstance?.mMetaData,
              canUpdateScriptDurationSettingsValue,
            )
          : inputInstance?.mMetaData,
        params.metadata,
      ) as MMetaDataField[];

      const items = (isLinear ? filterEditorMeta(content?.document) : []) as AutomationItem[];

      await handleInstanceUpdate({ ...params, metadata: updatedMetadata, items });
    },
    [
      canUpdateScriptDurationSettingsValue,
      handleDurationFieldsChange,
      handleInstanceUpdate,
      hostReadSpeed,
    ],
  );

  const saveAll = useCallback(
    async (params: UpdateInputParameters): Promise<void> => {
      const { instance: inputInstance, version, unlock } = params;
      if (!inputInstance || !(writeLockRef.current || version) || contentLoading) return;

      if (unlock) {
        writeLockRef.current = false;
      }

      setIsSavingInstance(true);
      await saveInstanceWithContent(params);
      setIsSavingInstance(false);
      setHasChanges(false);
    },
    [contentLoading, saveInstanceWithContent, setHasChanges, setIsSavingInstance, writeLockRef],
  );

  const [debouncedSaveAll, cancelDebounce] = useDebouncedCallback(saveAll, 15000);

  const update = useCallback(
    async (input: UpdateInstanceInput) => {
      try {
        cancelDebounce();
        setIsSavingInstance(true);
        await updateStoryInstance({
          variables: {
            input,
          },
          optimisticResponse: {
            updateInstance: {
              ...instance,
              ...(input as Partial<Instance>),
            },
          },
        });
      } catch (err) {
        //
      } finally {
        setIsSavingInstance(false);
      }
    },
    [cancelDebounce, instance, setIsSavingInstance, updateStoryInstance],
  );

  const updateInstanceSchedule = useCallback(
    async ({
      publishTime,
      newExpiryTime,
    }: {
      publishTime?: string | null;
      newExpiryTime?: string | null;
    }) => {
      if (!instance?.mId) return;

      let input: UpdateInstanceInput = {
        mId: instance?.mId,
        ...(publishTime !== instance?.mPublishingAt && { mPublishingAt: publishTime }),
        ...(newExpiryTime !== instance?.mPublishingEnd && {
          mPublishingEnd: newExpiryTime,
        }),
      };

      input = publishTime
        ? addInstancePublishDetails({
            input,
            platform: instance?.mProperties?.platform,
            mState: instance?.mState,
            approvalState,
            hasAccessToApprovalState,
            isApproved,
            isRestrictedPlatform: restrictedPlatform,
          })
        : input;

      await update(input);
    },
    [
      approvalState,
      hasAccessToApprovalState,
      instance?.mId,
      instance?.mProperties?.platform,
      instance?.mPublishingAt,
      instance?.mPublishingEnd,
      instance?.mState,
      isApproved,
      restrictedPlatform,
      update,
    ],
  );

  const changeDestination = useCallback(
    async (account?: PlatformAccount) => {
      if (!instance?.mId || !account) return;

      const input = {
        mId: instance?.mId,
        mProperties: {
          __typename: 'PlatformType',
          platform: instance?.mProperties?.platform,
          account: {
            accountId: account?.accountId,
            accountUrl: account?.accountUrl,
            accountLogo: account?.accountLogo,
            accountTitle: account?.accountTitle,
          },
        },
      };

      await update(input);
    },
    [instance?.mId, instance?.mProperties?.platform, update],
  );

  const savePublishSettings = useCallback(
    async ({
      account,
      publishTime,
      newExpiryTime,
    }: {
      account?: PlatformAccount;
      newExpiryTime?: string | null;
      publishTime?: string | null;
    }) => {
      if (!instance?.mId) return;

      let input: UpdateInstanceInput = {
        mId: instance?.mId,
        mProperties: {
          __typename: 'PlatformType',
          platform: instance?.mProperties?.platform,
          account: {
            accountId: account?.accountId,
            accountUrl: account?.accountUrl,
            accountLogo: account?.accountLogo,
            accountTitle: account?.accountTitle,
          },
        },
        ...(publishTime !== instance?.mPublishingAt && { mPublishingAt: publishTime }),
        ...(newExpiryTime !== instance?.mPublishingEnd && { mPublishingEnd: newExpiryTime }),
      };

      if (
        isApprovalWorkflowEnabled &&
        hasAccessToApprovalState &&
        approvalState &&
        account &&
        publishTime
      ) {
        /** platform workflow is enabled and user has direct publishing rights */
        input = {
          ...input,
          mState: approvalState,
        };
      }

      await update(input);
    },
    [
      approvalState,
      hasAccessToApprovalState,
      instance?.mId,
      instance?.mProperties?.platform,
      instance?.mPublishingAt,
      instance?.mPublishingEnd,
      isApprovalWorkflowEnabled,
      update,
    ],
  );

  const changeTitle = useCallback(
    async (title?: string) => {
      if (!instance?.mId || !title) return;
      const input = {
        mId: instance?.mId,
        mTitle: title,
      };
      await update(input);
    },
    [instance?.mId, update],
  );

  const changeDescription = useCallback(
    async (description: string) => {
      if (!instance?.mId) return;
      const input = {
        mId: instance?.mId,
        mDescription: description,
      };
      await update(input);
    },
    [instance?.mId, update],
  );

  const changeStatus = useCallback(
    async (status?: string) => {
      if (!instance?.mId || !status) return;
      const input = {
        mId: instance?.mId,
        mState: status,
      };
      await update(input);
    },
    [instance?.mId, update],
  );

  const onInstanceChanged = useCallback(
    async (instanceChangeObject: InstanceChangeObject) => {
      const { changeType } = instanceChangeObject;

      switch (changeType) {
        case 'publishingSchedule': {
          const publishTime = instanceChangeObject.schedule
            ? instanceChangeObject.schedule.publishTime
            : undefined;
          return updateInstanceSchedule({ publishTime });
        }

        case 'expirySchedule': {
          const newExpiryTime = instanceChangeObject.schedule.expiryTime;
          return updateInstanceSchedule({ publishTime: instance?.mPublishingAt, newExpiryTime });
        }

        case 'schedule-and-expiry': {
          const publishTime = instanceChangeObject.schedule.publishTime;
          const newExpiryTime = instanceChangeObject.schedule.expiryTime;
          return updateInstanceSchedule({ publishTime, newExpiryTime });
        }

        case 'destination': {
          const account = platform?.mProperties?.accounts?.find(
            (a) => a.accountTitle === instanceChangeObject.accountUrl,
          );

          return changeDestination(account);
        }

        case 'schedule-and-destination': {
          const publishTime = instanceChangeObject.publishingSettings.publishTime;
          const account = platform?.mProperties?.accounts?.find(
            (a) => a.accountTitle === instanceChangeObject.publishingSettings?.accountUrl,
          );

          return savePublishSettings({
            publishTime,
            account,
            newExpiryTime: instance?.mPublishingEnd,
          });
        }

        case 'expiry-and-destination': {
          const newExpiryTime = instanceChangeObject.publishingSettings?.expiryTime;
          const account = platform?.mProperties?.accounts?.find(
            (a) => a.accountTitle === instanceChangeObject.publishingSettings.accountUrl,
          );

          return savePublishSettings({
            account,
            publishTime: instance?.mPublishingAt,
            newExpiryTime,
          });
        }

        case 'schedule-and-destination-and-expiry': {
          const publishTime = instanceChangeObject.publishingSettings.publishTime;
          const newExpiryTime = instanceChangeObject.publishingSettings.expiryTime;
          const account = platform?.mProperties?.accounts?.find(
            (a) => a.accountTitle === instanceChangeObject.publishingSettings?.accountUrl,
          );

          return savePublishSettings({ publishTime, account, newExpiryTime });
        }

        case 'content': {
          setIsSavingInstance(true);
          const file = new window.File(
            [JSON.stringify(instanceChangeObject.content)],
            'content.data',
            {
              type: 'text/plain',
            },
          );

          try {
            return await uploadToS3(instance?.mContentKey, file);
          } catch (err) {
            return err;
          } finally {
            setIsSavingInstance(false);
          }
        }

        case 'title': {
          return changeTitle(instanceChangeObject.title);
        }

        case 'status': {
          return changeStatus(instanceChangeObject.status);
        }

        case 'description': {
          return changeDescription(instanceChangeObject.description ?? '');
        }

        default:
          return null;
      }
    },
    [
      updateInstanceSchedule,
      instance?.mPublishingAt,
      instance?.mPublishingEnd,
      instance?.mContentKey,
      platform?.mProperties?.accounts,
      changeDestination,
      savePublishSettings,
      setIsSavingInstance,
      changeTitle,
      changeStatus,
      changeDescription,
    ],
  );

  const updateLockedByUser = useCallback(
    (lockedId: string) => {
      const newUserId = getUserIdFromLockedId(lockedId);
      if (!newUserId) return;
      const newLockedByUser = getUserTitle(newUserId);
      if (!newLockedByUser) return;
      setLockedByUser(newLockedByUser);
    },
    [getUserTitle, setLockedByUser],
  );

  const updateLock = useCallback(
    (lockedId?: string) => {
      if (lockedId) {
        const isLockedByCurrentUser = userId === getUserIdFromLockedId(lockedId);
        const isSameScope = currentEditingScope === getScopeFromLockedId(lockedId);
        const isSameSession = getDinaSessionId(userId) === getDinaSessionIdFromLockedId(lockedId);

        lockedIdRef.current = lockedId;
        setLockedByCurrentUser(isLockedByCurrentUser);
        if (isLockedByCurrentUser && isSameScope && isSameSession) {
          setWriteLock(true);
          setReadLock(false);
          setOwnsLockElsewhere(false);
          writeLockRef.current = true;
        } else {
          setWriteLock(false);
          setReadLock(true);
          setOwnsLockElsewhere(isLockedByCurrentUser && !isSameSession);
          updateLockedByUser(lockedId);
          writeLockRef.current = false;
        }
      } else {
        window.requestAnimationFrame(() => {
          lockedIdRef.current = null;
          setWriteLock(false);
          setReadLock(false);
          setOwnsLockElsewhere(false);
          setLockedByCurrentUser(false);
          writeLockRef.current = false;
          resetLockToken(currentEditingScope);
        });
      }
    },
    [
      currentEditingScope,
      lockedIdRef,
      setLockedByCurrentUser,
      setReadLock,
      setWriteLock,
      updateLockedByUser,
      userId,
      writeLockRef,
    ],
  );

  const resetEditorValue = useCallback(
    (newValue: EditorValue) => {
      if (newValue) {
        const slateValue = isOlderSlateValue(newValue)
          ? (migrateValue(newValue, {}) as EditorValue)
          : { ...newValue };
        const value = replaceTab(slateValue);
        if (isEqual(editorValueRef.current, value)) return;
        setEditorData(value);
        editorValueRef.current = value;
      } else if (isNull(newValue)) {
        setEditorData(null);
        editorValueRef.current = null;
      }
    },
    [editorValueRef, setEditorData],
  );

  const releaseWriteLock = useCallback(
    async (version: string = 'ManualSaved') => {
      if (
        !instance?.locked ||
        userId !== getUserIdFromLockedId(instance.locked) ||
        currentEditingScope !== getScopeFromLockedId(instance.locked) ||
        getDinaSessionId(userId) !== getDinaSessionIdFromLockedId(instance.locked)
      )
        return;

      cancelDebounce();
      const params: UpdateInputParameters = {
        instance: { ...instance },
        unlock: true,
        audit: {
          source: 'useInstanceCore:releaseWriteLock',
        },
        ...(editorValueRef.current && { content: editorValueRef.current }),
        version: version,
      };

      await saveAll(params);
      initialContentRef.current = editorValueRef.current;
      updateLock();

      if (editorValueRef.current) resetEditorValue(editorValueRef.current);
    },
    [
      instance,
      userId,
      currentEditingScope,
      cancelDebounce,
      editorValueRef,
      saveAll,
      initialContentRef,
      updateLock,
      resetEditorValue,
    ],
  );

  const onForceUnlock = useCallback(async () => {
    if (!instance?.mId) return;

    const params = {
      instance: { mId: instance?.mId, mRefId: instance?.mRefId },
      unlock: true,
      audit: {
        source: 'useInstanceCore:forceUnlock',
      },
    };

    await updateInstance({
      ...params,
      instanceId: instance?.mId,
      metadata: undefined,
      items: [],
      autosave: false,
      content: undefined,
      onMetadataUpdated: () => {},
      version: undefined,
    });
  }, [instance?.mId, instance?.mRefId, updateInstance]);

  const handleLockInstance = useCallback(async () => {
    if (
      !writeLock &&
      !readLock &&
      !disableEdit &&
      !contentLoading &&
      !isSavingInstance &&
      !locking
    ) {
      if (!instance?.mId) return;

      await contentRefetchRef.current?.();
      setLocking(true);

      const result = await lockInstance(instance?.mId, userId, currentEditingScope);

      if (result?.data?.lockMember) {
        const { locked } = result.data.lockMember;
        updateLock(locked);
        setLocking(false);
        return locked;
      }

      setLocking(false);
      updateLock();
      return undefined;
    }
    return undefined;
  }, [
    writeLock,
    readLock,
    disableEdit,
    contentLoading,
    isSavingInstance,
    locking,
    instance?.mId,
    contentRefetchRef,
    setLocking,
    lockInstance,
    userId,
    currentEditingScope,
    updateLock,
  ]);

  const onChange = useCallback(
    (
      value: EditorValue,
      updatedInstance: Instance,
      createVersion = false,
      version: string = 'asset',
    ) => {
      if (writeLockRef.current) {
        setHasChanges(true);
        const params: UpdateInputParameters = {
          content: value,
          instance: updatedInstance,
          autosave: !createVersion,
          audit: { source: 'useInstanceCore:onChange' },
        };

        if (editorValueRef.current?.properties?.version !== value?.properties?.version) {
          params.content = {
            ...(params.content as EditorValue),
            properties: editorValueRef.current?.properties,
          };
        }

        editorValueRef.current = params.content as EditorValue;

        if (createVersion) {
          cancelDebounce();
          params.version = version;
          void saveAll(params);
        } else {
          void debouncedSaveAll(params);
        }
      }
    },
    [debouncedSaveAll, cancelDebounce, editorValueRef, saveAll, setHasChanges, writeLockRef],
  );

  const handleCancel = useCallback(async () => {
    if (!instance?.mId) return;
    setIsCancelled(true);

    const initialValue = isNull(initialContentRef.current)
      ? getPlatformInitialValue()
      : initialContentRef.current;

    const params: UpdateInputParameters = {
      instance: { ...instance },
      unlock: true,
      audit: {
        source: 'useInstanceCore:cancelled',
      },
      content: initialValue,
    };

    cancelDebounce();
    await saveAll(params);
    resetEditorValue(initialValue);
    updateLock();
    setIsCancelled(false);
  }, [
    instance,
    setIsCancelled,
    initialContentRef,
    getPlatformInitialValue,
    cancelDebounce,
    saveAll,
    resetEditorValue,
    updateLock,
  ]);

  const handleEditorUpdate = useCallback(
    async (input: EditorUpdateInput) => {
      const { type, payload } = input;
      if (type === 'asset-insert') {
        const { file, data, bypassUploadApi, uploadProgressCallback } = payload;
        return onAssetInsert(file, data, bypassUploadApi, uploadProgressCallback);
      }

      if (type === 'create-asset') {
        const { asset } = payload;
        if (!asset || !instance?.mStoryId) return;
        return createAsset(instance?.mStoryId, asset);
      }

      if (type === 'asset-update') {
        const { asset } = payload;
        if (!asset || !instance?.mStoryId) return;
        return updateAsset(instance?.mStoryId, asset);
      }

      if (type === 'create-placeholder') {
        const { title, itemType } = payload;
        return handleCreatePlaceholder(title, itemType);
      }

      if (type === 'remove-placeholder') {
        const { placeholder } = payload;
        void removePlaceholder({ ...placeholder, instanceId: instance?.mId });
      }

      if (type === 'change' || type === 'commit-update') {
        let newMetaData: MMetaDataField[] = [];
        if (variant === variants.TWITTER) {
          newMetaData = handleTwitterThreadChange(payload, metadata);
        } else if (variant === variants.LINEAR) {
          newMetaData = instanceRef.current?.mMetaData ?? defaultMetadata;
        }

        setWordCount(getWordCount(payload.document));

        if (instanceRef?.current?.mId) {
          onChange(
            payload,
            { ...instanceRef.current, mMetaData: newMetaData },
            type === 'commit-update',
            type === 'commit-update' ? input.commitFor : undefined,
          );
        }

        if (variant === variants.LINEAR) {
          void debouncedScriptDurationUpdate({
            content: payload,
            updatedMetadata: newMetaData,
          });
        }
      }

      return null;
    },
    [
      onAssetInsert,
      instance?.mStoryId,
      instance?.mId,
      createAsset,
      updateAsset,
      handleCreatePlaceholder,
      removePlaceholder,
      variant,
      setWordCount,
      instanceRef,
      handleTwitterThreadChange,
      metadata,
      defaultMetadata,
      onChange,
      debouncedScriptDurationUpdate,
    ],
  );

  const onMetadataChanged = useCallback(
    async (newMetadata: MMetaDataField[], newInstance: Instance) => {
      updateMetadataState(newMetadata);
      const params = {
        metadata: newMetadata,
        instance: newInstance,
        audit: { source: 'useInstanceCore:onMetadataChanged' },
      };
      await handleInstanceUpdate(params);
    },
    [handleInstanceUpdate, updateMetadataState],
  );

  const handleClipDurationChange = useCallback(
    async (newClipDuration: string, manual = !autoClipDurationSettingsValue) => {
      if (!instance) return;
      const newTotalDuration = getTime(getSeconds(scriptDuration) + getSeconds(newClipDuration));
      const updatedDurations = [
        { key: findDurationKey(durationTypes.TOTAL_DURATION) ?? '', value: newTotalDuration },
        { key: findDurationKey(durationTypes.CLIP_DURATION) ?? '', value: newClipDuration, manual },
      ];

      await onMetadataChanged(updatedDurations, instance);
    },
    [autoClipDurationSettingsValue, instance, scriptDuration, findDurationKey, onMetadataChanged],
  );

  const handleScriptDurationChange = useCallback(
    async (newScriptDuration: string, manual = !canUpdateScriptDurationSettingsValue) => {
      if (!instance) return;
      const scriptAutoValue = metadata?.find(({ key }) =>
        key.includes(durationTypes.SPEAK_DURATION),
      )?.autoValue;

      const newClipDuration = getDuration(metadata, durationTypes.CLIP_DURATION);
      const newTotalDuration = getTime(getSeconds(newScriptDuration) + getSeconds(newClipDuration));
      const updatedDurations = [
        { key: findDurationKey(durationTypes.TOTAL_DURATION) ?? '', value: newTotalDuration },
        {
          key: findDurationKey(durationTypes.SPEAK_DURATION) ?? '',
          value: newScriptDuration,
          autoValue: scriptAutoValue || '00:00',
          manual,
        },
      ];

      await onMetadataChanged(updatedDurations, instance);
    },

    [canUpdateScriptDurationSettingsValue, instance, metadata, findDurationKey, onMetadataChanged],
  );

  const updateEditStatus = useCallback(
    (shouldUpdateInstance: boolean) => {
      if (!instance) return;
      if (!isLinearInstance(instance) || canEditReadyInstance) {
        setDisableEdit(!shouldUpdateInstance);
        return;
      }
      setDisableEdit(instance?.mProperties?.account?.orderType === 'ready');
    },
    [canEditReadyInstance, instance, setDisableEdit],
  );

  const onDeleteInstance = useCallback(async () => {
    await archiveMember(
      instance?.mId,
      memberTypes.INSTANCE,
      instance?.mProperties?.platform === 'linear' ? (currentDestination?.id as string) : '',
      instance?.mStoryId,
    );
  }, [
    archiveMember,
    currentDestination?.id,
    instance?.mId,
    instance?.mProperties?.platform,
    instance?.mStoryId,
  ]);

  const onCreateDuplicate = useCallback(
    async (rundownId: string) => {
      /** save current content first so newly created instance gets updated values */
      if (hasChanges && instanceRef.current?.mId) {
        const params = {
          instance: { ...instanceRef.current },
          unlock: true,
          audit: { source: 'useInstanceCore:beforeCreatingDuplicate' },
          ...(editorValueRef.current && { content: editorValueRef.current }),
        };

        await saveInstanceWithContent(params);
      }

      if (!rundownId) {
        await createDuplicateInstance(instance?.mId, null, {});
      } else {
        try {
          const { data: rundownData }: { data: { getRundown: Rundown } } = await client.query({
            query: GET_RUNDOWN,
            variables: {
              input: {
                mId: rundownId,
                mRefId: rundownId,
              },
            },
            fetchPolicy: 'network-only',
          });
          await createDuplicateInstance(instance?.mId, rundownId, rundownData?.getRundown);
        } catch (e) {
          //
        }
      }
    },
    [
      client,
      createDuplicateInstance,
      editorValueRef,
      hasChanges,
      instance?.mId,
      instanceRef,
      saveInstanceWithContent,
    ],
  );

  useEffect(() => {
    if (s3Data) {
      resetEditorValue(s3Data);
      initialContentRef.current = s3Data;
      setWordCount(getWordCount(s3Data.document));
      setSkipDownload(true);
    } else {
      const initialValue = getPlatformInitialValue();
      resetEditorValue(initialValue);
      setWordCount(getWordCount(initialValue.document));
      initialContentRef.current = initialValue;
    }
  }, [
    initialContentRef,
    resetEditorValue,
    s3Data,
    setSkipDownload,
    getPlatformInitialValue,
    setWordCount,
  ]);

  useEffect(() => {
    if (lockedIdRef.current && lockedIdRef.current !== instance?.locked) {
      contentRefetchRef.current?.().then(
        () => {},
        () => {},
      );
    }
  }, [contentRefetchRef, instance?.locked, lockedIdRef]);

  useEffect(() => {
    if (platforms) {
      setPlatform(
        platforms.find((p) => p.mProperties?.platform === instance?.mProperties?.platform) ?? null,
      );
    }
  }, [instance?.mProperties?.platform, platforms, setPlatform]);

  useEffect(() => {
    const pvariant = instance ? getPlatformVariant(instance) ?? null : null;
    setPlatformVariant(pvariant);
  }, [getPlatformVariant, instance, setPlatformVariant]);

  useEffect(() => {
    if (
      destination.id !== currentDestination?.id ||
      destination.value !== currentDestination?.value
    ) {
      setCurrentDestination(destination);
    }

    updateEditStatus(canUpdateInstance);
  }, [
    canUpdateInstance,
    currentDestination,
    destination,
    instance,
    setCurrentDestination,
    updateEditStatus,
  ]);

  useEffect(() => {
    loadingRef.current = contentLoading || locking;
  }, [contentLoading, loadingRef, locking]);

  beforeUnloadRef.current = useCallback(
    async (lastInstance: Instance | null, latestContent: EditorValue | null) => {
      const currentSessionId = getDinaSessionId(userId);
      if (
        contentLoading ||
        locking ||
        isSavingInstance ||
        !lastInstance?.locked ||
        userId !== getUserIdFromLockedId(lastInstance.locked) ||
        currentEditingScope !== getScopeFromLockedId(lastInstance.locked) ||
        currentSessionId !== getDinaSessionIdFromLockedId(lastInstance.locked)
      )
        return;

      cancelDebounce();

      const params = {
        instance: { ...lastInstance },
        unlock: true,
        audit: { source: 'useIntanceCore:beforeUnloadRef' },
        ...(latestContent && { content: latestContent }),
      };

      await saveInstanceWithContent(params);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cancelDebounce, contentLoading, isSavingInstance, locking, saveInstanceWithContent, userId],
  );

  return {
    editorValue,
    setSkipDownload,
    contentLoading,
    releaseWriteLock,
    onForceUnlock,
    handleLockInstance,
    onChange,
    lockedByUser,
    lockedByCurrentUser,
    locking,
    writeLockRef,
    writeLock,
    readLock,
    editorData,
    editorValueRef,
    handleCancel,
    onInstanceChanged,
    saveAll,
    debouncedSaveAll,
    cancelDebounce,
    resetEditorValue,
    onDone: releaseWriteLock,
    placeHolderFormatValues,
    handleEditorUpdate,
    handleInstanceUpdate,
    handleClipDurationChange,
    handleScriptDurationChange,
    clipDuration,
    totalDuration,
    scriptDuration,
    wordCount,
    onDeleteInstance,
    updateLock,
    updateRelatedMembersMutation,
    updatingRelatedMembers,
    loading: contentLoading || storyLoading || locking || platformsLoading,
    onMetadataChanged,
    onCreateDuplicate,
    placeholderConfigs: {
      template: placeholderFormat,
      s3Content: editorValueRef.current,
      variables: placeHolderFormatValues,
    },
    instanceRef,
    saveInstanceWithContent,
    userId,
    twitterThreadCount,
    onSaveHotkeyVersion,
  };
};

export default useInstanceCore;
