import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { slateNodesToInsertDelta, withCursors, withYjs, YjsEditor } from '@slate-yjs/core';
import { Editor as SlateEditor, Transforms } from 'slate';
import { Awareness } from 'y-protocols/awareness';
import * as Y from 'yjs';

import { EMPTY_VALUE_FOR_SYNC } from 'components/editor';
import Editor, { getDefaultValue } from 'components/editor/Editor';
import {
  BaseEditor,
  EditorCustomization,
  EditorProps,
  Update,
  UpdateInput,
} from 'components/editor/types';
import LoadingIndicator from 'components/loadingIndicator';
import UserContext from 'contexts/UserContext';
import { CustomChannel } from 'types/customChannel';

import { CursorData, Cursors } from './Cursors';
import { CustomYjsProvider } from './CustomYjsProvider';
import { yjsLog } from './yjsUtils';

const emptyNode = {
  type: 'paragraph',
  children: [{ text: '' }],
};

function createCustomizeEditor(
  sharedType: Y.XmlText,
  userId: string,
  provider: { readonly awareness: Awareness },
) {
  return (editor: BaseEditor) => {
    const data: CursorData = { userId };
    const e = withCursors(withYjs(editor, sharedType), provider.awareness, { data });
    const { normalizeNode } = e;
    e.normalizeNode = (entry) => {
      const [node] = entry;

      if (!SlateEditor.isEditor(node) || node.children.length > 0) {
        return normalizeNode(entry);
      }
      Transforms.insertNodes(editor, emptyNode, { at: [0] });
    };
    return e;
  };
}

export interface CollaborativeEditorProps extends Omit<EditorProps, 'editorCustomization'> {
  variant: Parameters<typeof getDefaultValue>[0];
  yjsSyncChannel: CustomChannel;
  onReady: () => void;
}

export function CollaborativeEditor({
  yjsSyncChannel,
  update,
  onReady,
  ...rest
}: Readonly<CollaborativeEditorProps>) {
  const user = useContext(UserContext);
  const [connected, setConnected] = useState(false);
  const [sharedType, setSharedType] = useState<Y.XmlText>();
  const [provider, setProvider] = useState<CustomYjsProvider | null>(null);
  const initialValueRef = useRef(rest.value);
  if (initialValueRef.current !== rest.value) {
    initialValueRef.current = rest.value;
  }
  const readOnly = rest.readOnly;
  const onReadyRef = useRef<undefined | (() => void)>(onReady);
  const editorCustomization = useMemo(() => {
    if (!sharedType || !provider) return undefined;
    const customizeEditor = createCustomizeEditor(sharedType, user.mId, provider);
    const result: EditorCustomization<ReturnType<typeof customizeEditor>> = {
      customizeEditor,
      editableWrapper: Cursors,
      onMounted: (editor) => {
        YjsEditor.connect(editor);
        return () => YjsEditor.disconnect(editor);
      },
    };
    return result;
  }, [sharedType]);

  const onUpdate = useCallback(
    async (input: UpdateInput) => {
      if (!update) return;
      const result = await update(input);
      return result;
    },
    [update],
  );

  const onSync = useCallback(
    (synced: boolean) => {
      setConnected(synced);
      if (onReadyRef.current) {
        onReadyRef.current();
        onReadyRef.current = undefined;
      }
    },
    [setConnected, onReadyRef],
  );

  // Set up custom Yjs provider
  useEffect(() => {
    const isInitiator = initialValueRef.current !== EMPTY_VALUE_FOR_SYNC;
    yjsLog?.('init provider', isInitiator);
    const yDoc = new Y.Doc();
    const yProvider = new CustomYjsProvider(yDoc, yjsSyncChannel, isInitiator, !!readOnly);
    const sharedDoc = yDoc.get('slate', Y.XmlText);
    // Load the initial value into the yjs document
    const initialDoc =
      rest.value?.document ?? getDefaultValue(rest.variant, !!rest.isAllowed).document;
    const delta = slateNodesToInsertDelta(initialDoc);
    sharedDoc.applyDelta(delta);
    yProvider.on('sync', onSync);

    setSharedType(sharedDoc);
    setProvider(yProvider);

    return () => {
      yjsLog?.('cleanup provider');
      yDoc?.destroy();
      yProvider?.off('sync', onSync);
      yProvider?.destroy();
    };
  }, [yjsSyncChannel, initialValueRef]);

  useEffect(() => {
    if (provider) provider.readOnly = !!readOnly;
  }, [provider, !!readOnly]);

  // Introduce the lines below to get info on the props that cause rerendering of Editor
  // const restProps = Object.keys(rest);
  // const safeRest: Record<string, unknown> = {};
  // restProps.forEach((key) => {
  //   safeRest[key] = (rest as unknown as typeof safeRest)[key];
  // });
  // const lastRest = useRef(safeRest);
  // if (lastRest.current !== safeRest) {
  //   const changedProps = Object.keys(safeRest).filter(
  //     (key) => safeRest[key] !== lastRest.current[key],
  //   );
  //   if (changedProps.length) {
  //     // eslint-disable-next-line no-console
  //     console.log('TEMP: Changed props for editor', changedProps.join(', '));
  //   }
  //   lastRest.current = safeRest;
  // }

  if (!connected || !sharedType || !provider || !user.attributes?.mTitle || !editorCustomization) {
    return (
      <div style={{ height: rest.height }}>
        <LoadingIndicator />
      </div>
    );
  }
  return (
    <Editor
      {...rest}
      editorCustomization={editorCustomization}
      update={onUpdate as Update}
      suppressChangeEvent
    />
  );
}
