/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useMemo } from 'react';

import Editor from 'components/editor/Editor';
import { PlaceholderConfigs, Update } from 'components/editor/types';
import useResourceDetails from 'hooks/useResourceDetails';
import { useDoubleClickToLockEditor, useUsers } from 'store';
import { EditorValue } from 'types';
import clickIfNothingSelected from 'utils/clickIfNothingSelected';

import { EditorUpdateInput } from '../hooks/useInstanceCore';
import useInstanceMetadata from '../hooks/useInstanceMetadata';
import useInstancePermissions from '../hooks/useInstancePermissions';
import useInstanceViewUtils from '../hooks/useInstanceViewUtils';
import useLoadAndSetContent from '../hooks/useLoadAndSetContent';
import { useInstanceMolecule } from '../store/instance';
import { EditorContentWrapper } from '../styled';

import EmbedView from './embedView';

interface BodyProps {
  loading: boolean;
  editorValue: EditorValue;
  placeholderConfigs: PlaceholderConfigs;
  handleEditorUpdate: (input: EditorUpdateInput) => Promise<unknown>;
  handleLockInstance: () => Promise<string | undefined>;
  onDone: (version?: string) => Promise<void>;
}

const Body = ({
  loading,
  editorValue,
  handleEditorUpdate,
  placeholderConfigs,
  handleLockInstance,
  onDone,
}: BodyProps) => {
  const {
    useInstanceValue,
    usePlatformVariant,
    useWriteLock,
    useReadLock,
    useReadOnlyValue,
    useView,
    onDoneHotkeyVersion,
    useEditorFontSize,
  } = useInstanceMolecule();
  const { canShowCmsIframe, canUploadMediaBySignedURL } = useInstancePermissions();
  const {
    thumbnail,
    isCMSInstance,
    onCmsEditingClick,
    onSetEditor,
    placeholder,
    textDirection,
    variant,
    isCmsBlock,
    showFacebookEmbed,
    showTwitterEmbed,
    showInstagramEmbed,
    showYoutubeEmbed,
    resetSelection,
  } = useInstanceViewUtils();
  const { hostReadSpeed } = useInstanceMetadata();

  const [users] = useUsers();
  const instance = useInstanceValue();
  const resourceDetails = useResourceDetails({
    resource: instance,
  });
  const [platformVariant] = usePlatformVariant();
  const [writeLock] = useWriteLock();
  const [readLock] = useReadLock();
  const readOnly = useReadOnlyValue();
  const [view] = useView();
  const [doubleClickToLockEditor] = useDoubleClickToLockEditor();
  const [editorFontSize] = useEditorFontSize();

  useLoadAndSetContent();

  const getPlaceholderConfigs = useCallback(() => placeholderConfigs, [placeholderConfigs]);

  useEffect(() => {
    resetSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writeLock]);

  const renderedEditor = useMemo(
    () => (
      <Editor
        height="100%"
        doLock={() => {
          handleLockInstance().catch(() => {});
        }}
        readOnly={!writeLock}
        readLock={readLock}
        update={handleEditorUpdate as Update}
        thumbnail={thumbnail}
        platformStructure={platformVariant?.platformStructure}
        value={editorValue}
        isAllowed={true}
        onCmsEditing={onCmsEditingClick}
        enableEditorCommand
        setEditor={onSetEditor}
        placeholder={placeholder}
        withSignedUrl={canUploadMediaBySignedURL}
        direction={textDirection}
        resourceDetails={resourceDetails}
        users={users}
        variant={variant}
        hostReadSpeed={hostReadSpeed}
        isPublished={!!instance?.mPublishingAt}
        isCmsBlock={isCmsBlock}
        editorFontSize={editorFontSize}
        getPlaceholderConfigs={getPlaceholderConfigs}
        platformId={platformVariant?.id}
        platformKind={instance?.mProperties?.platformKind}
        renderToolbar={readOnly ? () => null : undefined}
        onSave={() => onDone(onDoneHotkeyVersion)}
      />
    ),
    [
      writeLock,
      readLock,
      handleEditorUpdate,
      thumbnail,
      platformVariant?.platformStructure,
      platformVariant?.id,
      editorValue,
      isCMSInstance,
      canShowCmsIframe,
      onCmsEditingClick,
      onSetEditor,
      placeholder,
      canUploadMediaBySignedURL,
      textDirection,
      resourceDetails,
      users,
      variant,
      hostReadSpeed,
      instance?.mPublishingAt,
      instance?.mProperties?.platformKind,
      isCmsBlock,
      editorFontSize,
      placeholderConfigs,
      readOnly,
      handleLockInstance,
      onDone,
      onDoneHotkeyVersion,
    ],
  );

  const content =
    view === 'edit' ? (
      renderedEditor
    ) : (
      <EmbedView
        showTwitterEmbed={showTwitterEmbed}
        showYoutubeEmbed={showYoutubeEmbed}
        showFacebookEmbed={showFacebookEmbed}
        showInstagramEmbed={showInstagramEmbed}
        provider={instance?.mProperties?.provider}
        readLock={readLock}
        writeLock={writeLock}
      />
    );

  return (
    <EditorContentWrapper
      role="presentation"
      onClick={(ev) => {
        if (doubleClickToLockEditor) return;
        clickIfNothingSelected(ev, handleLockInstance);
      }}
      onDoubleClick={() => {
        if (!doubleClickToLockEditor) return;
        handleLockInstance().then(
          () => {},
          () => {},
        );
      }}
      $disabled={loading}
    >
      {content}
    </EditorContentWrapper>
  );
};

export default memo(Body);
