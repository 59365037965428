import { KeyboardEvent, useCallback, useEffect, useState } from 'react';

import Tooltip from 'components/tooltip';
import { FieldTypeEnum, type FieldValue } from 'types/graphqlTypes';

import { FieldHeader } from '../../styled';
import type FieldProps from '../fieldProps';

import { StyledTextField, TextWrapper } from './styled';

export const getValue = (value: FieldValue, type: FieldTypeEnum) => {
  if (type === FieldTypeEnum.text) {
    if (typeof value === 'string') {
      return value;
    }
  }

  if (type === FieldTypeEnum.number) {
    if (typeof value === 'number') {
      return value;
    }

    if (value) {
      return +value;
    }
  }

  return null;
};

const getNumber = (value: string | number): number | null => {
  if (typeof value === 'string') {
    if (value.length) return Number(value);
    return null;
  }
  return value;
};

export function TextField({
  fieldModel,
  fieldSettings,
  defaultFieldSettings,
  value,
  setValue,
  style,
  errorMessage,
  autoFocus,
  fireOnChange,
  disableEdit,
  onHotKeys,
}: Readonly<FieldProps>) {
  const [liveValue, setLiveValue] = useState<string | number | null>(null);
  const { fieldId, type } = fieldModel;
  const { hint, label, multiline = false, fieldHeight = 3 } = fieldSettings ?? defaultFieldSettings;

  useEffect(() => {
    setLiveValue(getValue(value, type));
  }, [value]);

  const doBlur = useCallback(() => {
    if (liveValue === value) return;

    if (typeof liveValue === 'number') {
      setValue(liveValue);
    } else if (typeof liveValue === 'string') {
      setValue(liveValue.length > 0 ? liveValue : null);
    } else if (liveValue === null && value !== null && value !== undefined) {
      setValue(null);
    }
  }, [liveValue, setValue, value]);

  const doValue = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const val = type === FieldTypeEnum.text ? ev.target.value : getNumber(ev.target.value);
      if (fireOnChange) {
        setValue(val);
      } else {
        setLiveValue(val);
      }
    },
    [fireOnChange, setValue, type],
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      void onHotKeys?.(event, doBlur);
    },
    [onHotKeys, doBlur],
  );

  return (
    <Tooltip title={hint ?? ''}>
      <TextWrapper key={fieldId} style={style}>
        <FieldHeader>{label}</FieldHeader>
        <StyledTextField
          variant="filled"
          type={type === FieldTypeEnum.text ? 'text' : 'number'}
          placeholder={hint?.length > 0 ? hint : 'Enter your value here'}
          onChange={doValue}
          value={(fireOnChange ? value : liveValue) ?? ''}
          multiline={multiline}
          minRows={fieldHeight}
          autoFocus={autoFocus}
          tabIndex={disableEdit ? -1 : undefined}
          maxRows={20}
          error={Boolean(errorMessage)}
          helperText={errorMessage?.length ? errorMessage : undefined}
          inputProps={{
            onKeyDown,
            onBlur: doBlur,
            tabIndex: disableEdit ? -1 : undefined,
          }}
        />
      </TextWrapper>
    </Tooltip>
  );
}
